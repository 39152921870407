<template>
  <div>
    <b-card title="Client Details">
      <b-row>
        <b-col>
          <table class="table table-sm table-borderless">
            <tbody>
              <tr>
                <th style="width: 250px">
                  Client
                </th>
                <td>
                  {{ client.clientName }}
                </td>
              </tr>
              <tr v-if="client.firstTaskSessionStart">
                <th>First Task session started on</th>
                <td>
                  {{ client.firstTaskSessionStart | formatDateTimeTz(timezone) }}
                  <feather-icon
                    v-b-popover.hover="'The actual date of the first Task session of this client may vary, as only the jobs since the ONEInsight agent installation and max. 5 years back are considered.'"
                    class="ml-1"
                    icon="HelpCircleIcon"
                  />
                </td>
              </tr>
              <tr v-if="client.formattedIpAddress">
                <th>IP Address</th>
                <td>
                  {{ client.formattedIpAddress }}
                </td>
              </tr>
              <tr v-if="client.lastTaskSessionStart">
                <th>Last Task session started on</th>
                <td>
                  {{ client.lastTaskSessionStart | formatDateTimeTz(timezone) }}
                </td>
              </tr>
              <tr>
                <th>Task sessions</th>
                <td>
                  {{ client.taskSessionCount }}
                  <feather-icon
                    v-b-popover.hover="'The actual number of Task sessions of this client may vary, as only the jobs since the ONEInsight agent installation and max. 5 years back are considered.'"
                    class="ml-1"
                    icon="HelpCircleIcon"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <b-form inline>
        <b-form-group
          label="Time range"
          label-sr-only
        >
          <date-range-picker
            v-model="timeRange"
            :selectable-ranges="['last3Days', 'last7Days', 'last14Days', 'last30Days', 'last60Days', 'last90Days']"
            :max-selectable-days="90"
          />
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            v-model="onlyErrors"
            switch
          >
            Errors only
          </b-form-checkbox>
        </b-form-group>
      </b-form>
    </b-card>

    <b-card
      :title="`Task sessions (${filteredTaskSessions.length})`"
    >
      <TaskSessionTable
        :task-sessions="filteredTaskSessions"
        :timezone="timezone"
      />
    </b-card>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import {
  BRow, BCol, BCard, VBPopover, BFormCheckbox, BForm, BFormGroup,
} from 'bootstrap-vue'
import DateRangePicker from '@/components/dateRangePicker/DateRangePicker.vue'

import moment from '@/libs/moment'
import TaskSessionTable from './components/TaskSessionTable.vue'
import VeeamService from '@/service/veeam.service'

export default {
  components: {
    BRow, BCol, BCard, BFormCheckbox, BForm, BFormGroup, DateRangePicker, TaskSessionTable,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      client: {},
      timeRange: {
        range: 'last7Days',
      },
      onlyErrors: false,
      taskSessions: [],
    }
  },
  computed: {
    ...mapGetters({
      isCurrentUserHost: 'auth/isHost',
    }),
    clientName() {
      return this.$route.params.clientName
    },
    orderedTaskSessions() {
      return this.taskSessions
        .concat()
        .sort((a, b) => (moment(a.start) > moment(b.start) ? -1 : 1))
    },
    filteredTaskSessions() {
      if (this.onlyErrors === true) {
        return this.taskSessions.filter(x => x.calculatedStatus !== 0)
      }

      return this.taskSessions
    },
    timezone() {
      if (this.asup && this.asup.timezone) {
        return this.asup.timezone
      }

      return 'Etc/UTC'
    },
  },
  beforeMount() {
    if (this.$route.query.from && this.$route.query.to) {
      this.timeRange = {
        startDate: moment.utc(this.$route.query.from).toDate(),
        endDate: moment.utc(this.$route.query.to).toDate(),
      }
    }

    if (this.isCurrentUserHost === true) {
      this.onlyErrors = true
    }

    if (this.$route.query.onlyErrors === true) {
      this.onlyErrors = true
    }

    // Watch properties after setting timer range from query to avoid loading data too many times
    this.$watch('timeRange', this.loadData)
    this.$watch('onlyErrors', this.loadData)
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      VeeamService.getClientAsync(this.asup.id, this.clientName, { disableTenantFilter: true })
        .then(result => {
          this.client = result
        })

      VeeamService.getTaskSessionListAsync(this.asup.id, {
        from: moment(this.timeRange.startDate).format('YYYY-MM-DD'),
        to: moment(this.timeRange.endDate).format('YYYY-MM-DD'),
        client: this.clientName,
      }, { disableTenantFilter: true })
        .then(result => {
          // automatically show details for failed saveSetJobs
          for (let i = 0; i < result.items.length; i += 1) {
            if (result.items[i].calculatedStatus === 30) {
              // eslint-disable-next-line no-param-reassign
              result.items[i].showDetails = true
            }
          }

          this.taskSessions = result.items
        })
    },
  },
}
</script>

<style scoped>
  .form-inline {
    place-items: flex-start
  }

  .form-group {
    margin-right: 15px;
  }

  .success {
    fill: var(--success);
    color: var(--success);
  }
  .warning {
    fill: var(--warning);
    color: var(--warning);
  }
  .danger {
    fill: var(--danger);
    color: var(--danger);
  }
</style>
